
import { useState, useCallback, useEffect, useRef, } from 'react';
import debounce from 'lodash.debounce'
import FeedInput from './components/FeedInput';

import './App.css';


const SUCCESS_STATE = 'success';
const FAIL_STATE = 'failure';

const CLEAR_TIMEOUT = 1500;
const INPUT_DEBOUNCE_TIMEOUT = 100;


function App() {
  const inputOneEl = useRef(null)
  const inputTwoEl = useRef(null)

  const [matchState, setMatchState] = useState(null)
  const [inputOneValue, setInputOneValue] = useState('')
  const [inputTwoValue, setInputTwoValue] = useState('')


  const focusInputOne = useCallback(() => {
    inputOneEl.current.focus()
  }, [])

  const focusInputTwo = useCallback(() => {
    inputTwoEl.current.focus()
  }, [])

  const clearInputsAndFocus = useCallback((newInputVal = '') => {
    setMatchState(null)
    setInputOneValue(newInputVal.slice(-1) || '')
    setInputTwoValue('')
    focusInputOne()
  }, [focusInputOne])

  const handleInputOneChange = useCallback((e) => {
    setInputOneValue(e.target.value)
    setMatchState(null)
  }, [setInputOneValue])

  const handleInputTwoChange = useCallback((e) => {
    if (matchState) {
      clearInputsAndFocus(e.target.value)
    } else {
      setInputTwoValue(e.target.value)
      setMatchState(null)
    }
  }, [clearInputsAndFocus, matchState])


  const handleClearAll = useCallback(() => {
    clearInputsAndFocus()
  }, [clearInputsAndFocus])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFocusInputTwo = useCallback(debounce(focusInputTwo, INPUT_DEBOUNCE_TIMEOUT), [focusInputTwo])

  const checkForMatch = useCallback(() => {
    const isMatch = inputOneValue.slice(0, -1) === inputTwoValue.slice(0, -1)
    setMatchState(isMatch ? SUCCESS_STATE : FAIL_STATE)
  }, [inputOneValue, inputTwoValue])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCheckForMatch = useCallback(debounce(checkForMatch, INPUT_DEBOUNCE_TIMEOUT), [checkForMatch])


  const checkIfFocusedOnInput = useCallback((e) => {
    const isFocused = (
      document.activeElement === inputOneEl.current ||
      document.activeElement === inputTwoEl.current
    )

    if (!isFocused) {
      setInputOneValue(e.key)
      inputOneEl.current.focus()
    }
  }, [])


  // Focus Input #1 if not already
  useEffect(() => {
    window.addEventListener('keyup', checkIfFocusedOnInput);

    return () => {
      window.removeEventListener('keyup', checkIfFocusedOnInput)
    }
  }, [checkIfFocusedOnInput])


  // Check for match
  useEffect(() => {
    if (inputOneValue && inputTwoValue) {
      debouncedCheckForMatch()
    }
  }, [debouncedCheckForMatch, inputOneValue, inputTwoValue])


  // After match, set timeout
  useEffect(() => {
    let timeoutId;

    if (matchState) {
      timeoutId = window.setTimeout(() => {
        if (matchState) {
          clearInputsAndFocus('')
        }
      }, CLEAR_TIMEOUT);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [matchState, focusInputOne, clearInputsAndFocus])


  // Focus Input #2 after Input #1 value
  useEffect(() => {
    if (inputOneValue) {
      debouncedFocusInputTwo()
    }
  }, [inputOneValue, debouncedFocusInputTwo])


  const isSuccess = matchState === SUCCESS_STATE
  const isFailure = matchState === FAIL_STATE


  return (
    <main
      className={`
        page
        ${isSuccess ? 'match-success' : ''}
        ${isFailure ? 'match-failure' : ''}
      `}
    >
      <h2 className="page-title">
        Scan both sides of a tote
      </h2>

      <div className="input-group">
        <FeedInput
          value={inputOneValue}
          onChange={handleInputOneChange}
          autofocus
          label="Side #1"
          ref={inputOneEl}
        />
        <FeedInput
          value={inputTwoValue}
          onChange={handleInputTwoChange}
          label="Side #2"
          ref={inputTwoEl}
        />

        <button
          className="btn-clear-all"
          onClick={handleClearAll}
          type="button"
        >
          Reset
        </button>
      </div>

      <div className="result-output">
        {matchState === SUCCESS_STATE && (
          <div className="result-text success">
            Matched
          </div>
        )}
        {matchState === FAIL_STATE && (
          <div className="result-text fail">
            NOT MATCHED
          </div>
        )}
      </div>
    </main>
  );
}

export default App;
