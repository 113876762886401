import { forwardRef } from 'react';
import './styles.css'


const FeedInput = forwardRef(function ({
  value = '',
  onChange,
  label = 'label',
  autofocus = false,
}, ref) {
  return (
    <label className="feed-input-wrapper">
      <span className="feed-input-label">
        {label}
      </span>
      <input
        type="text"
        ref={ref}
        autoFocus={autofocus}
        value={value}
        onChange={onChange}
        className="feed-input"
      />
    </label>
  );
})

export default FeedInput;